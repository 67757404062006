<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item v-if="formDatas.eventtype==901" label="系统名称" prop="systemnameid">
        <a-select v-model="formDatas.systemnameid" style="width: 240px;">
          <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="代码条目类型" prop="eventtype">
        <a-select v-model="formDatas.eventtype" style="width: 240px;">
          <a-select-option v-for="(item, index) in eventOptions" :key="index" :value="item.key">{{item.value}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="代码条目名称" prop="eventname">
        <a-input v-model.trim="formDatas.eventname" placeholder="请输入" style="width:240px"></a-input>
      </a-form-model-item>
      <a-form-model-item label="代码条目编码" prop="eventcode">
        <a-input v-model.trim="formDatas.eventcode" placeholder="请输入" style="width: 240px;"></a-input>
      </a-form-model-item>
      <a-form-model-item label="代码子条目编码" prop="eventchildcode">
        <a-input v-model.trim="formDatas.eventchildcode" placeholder="请输入" style="width: 240px;"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否显示图片" prop="showimage">
        <a-select v-model.trim="formDatas.showimage"  style="width: 240px;">
          <a-select-option value="0">否</a-select-option>
          <a-select-option value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="代码条目描述" prop="eventdesc">
        <a-input v-model.trim="formDatas.eventdesc"  style="width: 240px;"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { addEvent, modifyEvent } from 'A/wlw'
import {eventOptions} from '@/json/wlwhistory'
import {getCache} from "U/index";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      eventOptions,
      modalVisible: false,
      systemNames:[],
      formDatas: {
        eventid:"",
        systemnameid:'',
        eventname: '',
        eventcode: '',
        eventchildcode: '',
        eventtype: '',
        eventdesc: '',
        showimage: '',
      },
      formRules: {
        // systemnameid: [{required: true, message: '请选择系统名称'}],
        eventtype: [{required: true, message: '请选择代码条目类型'}],
        eventname: [{required: true, message: '请输入代码条目名称'}],
        eventcode: [{required: true, message: '请输入代码条目编码'}],
        showimage: [{required: true, message: '请选择是否显示图片'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
    }
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            if(key=="systemnameid"){
              this.formDatas[key] = this.detailData[key].toString();
            }else{
              this.formDatas[key] = this.detailData[key];
            }
          }
        })
      }
    },
    resetDatas() {
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas,
            };
            if(this.showType == 'add') {
              addEvent(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyEvent(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>