<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="参数类型" prop="eventtype" style="width: 25%">
              <a-select v-model="queryParams.eventtype">
                <a-select-option v-for="(item, index) in eventOptions" :key="index" :value="item.key">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="场景类型" prop="scenetypeid" style="width: 25%">
              <a-select v-model="queryParams.scenetypeid">
                <a-select-option v-for="(item, index) in sceneTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="系统名称" prop="systemnameid" style="width: 25%">
              <a-select v-model="queryParams.systemnameid">
                <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="参数代码" prop="eventcode" style="width: 25%">
              <a-input v-model.trim="queryParams.eventcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="参数子代码" prop="eventchildcode" style="width: 25%">
              <a-input v-model.trim="queryParams.eventchildcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="参数名称" prop="eventname" style="width: 25%">
              <a-input v-model.trim="queryParams.eventname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">代码条目</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="eventid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventtype" slot-scope="value">{{eventTips[value]}}</span>
          <span slot="showimage" slot-scope="value">{{value==='1'?'是':'否'}}</span>
          <span slot="property" slot-scope="value"><a-tooltip :title="value"><span>{{value}}</span></a-tooltip></span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="modify(value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </div>
</template>

<script>
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import pagination from '@/mixins/pagination'
import {eventOptions,eventTips} from '@/json/wlwhistory'
import { getLiftListByCondition } from 'A/jcgn'
import { getEventListByCondition, deleteEvent } from 'A/wlw'
import AddOrEditModal from './AddOrEditModal'
export default {
  name: 'repairItem',
  mixins: [pagination],
  components: {
    AddOrEditModal
  },
  data() {
    return {
      showAdvanced: false,
      eventOptions,
      eventTips,
      systemNames:[],
      sceneTypes:'',
      queryParams: {
        eventtype: '',
        eventcode: '',
        eventchildcode: '',
        eventchildname: '',
        evnentname:'',
        systemnameid:'',
        scenetypeid:'',
      },
      tableColumns: [
        {
          title: '系统名称',
          dataIndex: 'systemname',
          key: 'systemname',
          ellipsis: true,
          scopedSlots: { customRender: 'systemname' }
        },
        {
          title: '场景名称',
          dataIndex: 'scenetype',
          key: 'scenetype',
          ellipsis: true,
        },
        {
          title: '参数名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
        },
        {
          title: '参数类型',
          dataIndex: 'eventtype',
          key: 'eventtype',
          ellipsis: true,
          scopedSlots: { customRender: 'eventtype' }
        },
        {
          title: '参数代码',
          dataIndex: 'eventcode',
          key: 'eventcode',
          ellipsis: true,
        },
        {
          title: '参数子代码',
          dataIndex: 'eventchildcode',
          key: 'eventchildcode',
          ellipsis: true,
        },
        {
          title: '子类型名称',
          dataIndex: 'eventchildname',
          key: 'eventchildname',
          ellipsis: true,
        },
        {
          title: '参数描述',
          dataIndex: 'eventdesc',
          key: 'eventdesc',
          ellipsis: true,
        },
        {
          title: '显示图片',
          dataIndex: 'showimage',
          key: 'showimage',
          ellipsis: true,
          scopedSlots: { customRender: 'showimage' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalShowType: 'add',
      modalVisible: false,
      modalDetailData: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.systemNames = dictionary.systemNames;
        this.systemNamesMap = dictionary.systemNamesMap;
        this.sceneTypes =  dictionary.sceneTypes;
      }
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventid);
      }).catch(()=>{
      });
    },
    delete(eventid) {
      this.showLoading();
      if(eventid) {
        let params = {
          eventid
        };
        deleteEvent(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    add() {
      this.modalShowType = 'add';
      this.modalDetailData = null;
      this.modalVisible = true;
    },
    modify(value, record) {
      this.modalShowType = 'edit';
      this.modalDetailData = record;
      this.modalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>